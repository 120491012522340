import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GET_API, POST_API } from "../../Services/api";
import config from "../../Services/config.json";
import Validation from "../Validation";
import CommentUser from "../../assets/images/blog/comments-1.png";
import { BsStar } from "react-icons/bs";

function About() {
  const loaderRef = useRef("");
  const [about, setAbout] = useState({});
  const [reviewList, setReviewList] = useState([]);
  const [formResponseMessage, setFormResponseMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showMoreLess, setShowMoreLess] = useState("Show more");
  const [hover, setHover] = useState(null);

  const [reviewForm, setReviewForm] = useState({
    email: "",
    name: "",
    title: "",
    rating: "",
    review: "",
  });

  useEffect(() => {
    getAboutDetails();
  }, []);

  useEffect(() => {
    getRatingAndReviewList();
  }, []);

  const handleExpand = (e) => {
    if (e.target.getAttribute("aria-expanded") === "true") {
      setShowMoreLess("Show less");
    } else {
      setShowMoreLess("Show more");
    }
  };

  const getRatingAndReviewList = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const reviewDetails = await GET_API("reviewsandRatingsList");
      if (reviewDetails?.data?.status === 200) {
        setReviewList(reviewDetails?.data?.data);
        loaderRef.current.classList.remove("preloader");
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const getAboutDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const aboutusDetails = await GET_API("aboutus");
      if (aboutusDetails?.data?.status === 200) {
        setAbout(aboutusDetails?.data?.data[0]);
        loaderRef.current.classList.remove("preloader");
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const handleReviewChange = (e) => {
    const { name, value } = e?.target;
    setReviewForm({ ...reviewForm, [name]: value });
  };

  const handleReviewFormSubmit = async (e) => {
    e?.preventDefault();

    const isValid = Validation.RatingAndReview(reviewForm);
    try {
      if (isValid && isValid?.error) {
        setButtonDisabled(false);
        setErrors({
          [isValid?.error?.details[0].path[0]]:
            isValid.error.details[0].message,
        });
        return;
      } else {
        setErrors({});
      }
      const addReviewForm = await POST_API("reviewsandRatings", reviewForm);
      if (addReviewForm?.data?.status === 200) {
        setFormResponseMessage(addReviewForm?.data?.message);
        setReviewForm({
          email: "",
          name: "",
          title: "",
          rating: "",
          review: "",
        });
      }
      getRatingAndReviewList();
    } catch (error) {
      setFormResponseMessage("Failed to add Review...!");
    }
  };

  return (
    <>
      <div ref={loaderRef}></div>
      {
        !about ? null : <div className="body_content">
        <section
          className="breadcumb-section2 p-0"
          style={{
            backgroundImage: `url(${config.path}${about?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title text-white text-center">
                    {about?.title}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-about pb90">
          <div className="container">
            <div className="row wow fadeInUp" data-wow-delay="300ms">
              <div className="col-lg-4">
                <h2>{about?.side_header_1}</h2>
              </div>

              <div className="col-lg-8">
                <p className="text mb10">{about?.description_less}</p>
                <div className="agent-single-accordion">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body p-0">
                          <p className="text">{about?.description_more}</p>
                        </div>
                      </div>
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button p-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                          onClick={(e) => {
                            handleExpand(e);
                          }}
                        >
                          {showMoreLess}
                        </button>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt60 pb90 bgc-f7">
          <div className="container">
            <div
              className="row wrap wow fadeInUp"
              data-wow-delay="500ms"
              style={{
                visibility: "visible",
                animationDelay: "500ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="col-lg-12">
                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                  <h4 className="title fz17 mb30">Video</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="property_video bdrs12 w-100"
                        style={{
                          backgroundImage: `url(${config.path}${about?.video_bg_image})`,
                        }}
                      >
                        <Link
                          className="video_popup_btn mx-auto popup-img popup-youtube"
                          to={about?.video_url}
                          target="_blank"
                        >
                          <span className="flaticon-play"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                  <h4 className="title fz17 mb30">{about?.side_header_2}</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="navtab-style1">
                        <nav>
                          <div
                            className="nav nav-tabs mb20"
                            id="nav-tab2"
                            role="tablist"
                          >
                            <button
                              className="nav-link fw600 active"
                              id="nav-item1-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-item1"
                              type="button"
                              role="tab"
                              aria-controls="nav-item1"
                              aria-selected="true"
                            >
                              Ton of events
                            </button>
                            <button
                              className="nav-link fw600"
                              id="nav-item2-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-item2"
                              type="button"
                              role="tab"
                              aria-controls="nav-item2"
                              aria-selected="false"
                            >
                              Done by Us
                            </button>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade fz15 active show"
                            id="nav-item1"
                            role="tabpanel"
                            aria-labelledby="nav-item1-tab"
                          >
                            <div className="nearby d-sm-flex align-items-center mb20">
                              <p>{about?.ton_of_events}</p>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade fz15"
                            id="nav-item2"
                            role="tabpanel"
                            aria-labelledby="nav-item2-tab"
                          >
                            <div className="nearby d-sm-flex align-items-center mb20">
                              <div className="rating dark-color mr15 ms-1 mt10-xs mb10-xs">
                                <span className="fw600 fz14">
                                  {about?.done_by_us_percentage_weddings}
                                </span>
                                <span className="text fz14">+</span>
                              </div>
                              <div className="details">
                                <p className="dark-color fw600 mb-0">
                                  Weddings
                                </p>
                              </div>
                            </div>
                            <div className="nearby d-sm-flex align-items-center  mb20">
                              <div className="rating style2 dark-color mr15 ms-1 mt10-xs mb10-xs">
                                <span className="fw600 fz14">
                                  {about?.done_by_us_percentage_party}
                                </span>
                                <span className="text fz14">+</span>
                              </div>
                              <div className="details">
                                <p className="dark-color fw600 mb-0">Party</p>
                              </div>
                            </div>
                            <div className="nearby d-sm-flex align-items-center ">
                              <div className="rating dark-color mr15 ms-1 mt10-xs mb10-xs">
                                <span className="fw600 fz14">
                                  {about?.done_by_us_percentage_happy_customers}
                                </span>
                                <span className="text fz14">+</span>
                              </div>
                              <div className="details">
                                <p className="dark-color fw600 mb-0">
                                  Happy Customers
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-about pt-60 ">
          <div className="container">
            <div className="row wow fadeInUp" data-wow-delay="300ms">
              <div className="col-lg-12">
                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                  <h4 className="title fz17 mb30">Leave A Review</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bsp_reveiw_wrt">
                        <form
                          className="comments_form"
                          onSubmit={(e) => {
                            handleReviewFormSubmit(e);
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="fw600 ff-heading mb-2">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  onChange={(e) => {
                                    handleReviewChange(e);
                                  }}
                                  value={reviewForm.email}
                                />
                                {errors.email ? (
                                  <div
                                    className="validation-error-label"
                                    style={{ marginTop: "4px" }}
                                  >
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="fw600 ff-heading mb-2">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder="Enter name"
                                  onChange={(e) => {
                                    handleReviewChange(e);
                                  }}
                                  value={reviewForm.name}
                                />
                                {errors.name ? (
                                  <div
                                    className="validation-error-label"
                                    style={{ marginTop: "4px" }}
                                  >
                                    {errors.name}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="fw600 ff-heading mb-2">
                                  Title
                                </label>
                                <input
                                  type="text"
                                  name="title"
                                  className="form-control"
                                  placeholder="Enter Title"
                                  onChange={(e) => {
                                    handleReviewChange(e);
                                  }}
                                  value={reviewForm.title}
                                />
                                {errors.title ? (
                                  <div
                                    className="validation-error-label"
                                    style={{ marginTop: "4px" }}
                                  >
                                    {errors.title}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="fw600 ff-heading mb-2">
                                  Rating
                                </label>
                                <div>
                                  {[...Array(5)].map((start, index) => {
                                    const currentRating = index + 1;
                                    return (
                                      <label>
                                        <input
                                          type="radio"
                                          name="radio"
                                          value={currentRating}
                                          onClick={() => {
                                            // setRating(currentRating);
                                            setReviewForm({
                                              ...reviewForm,
                                              rating: currentRating,
                                            });
                                          }}
                                        />
                                        <BsStar
                                          className="star"
                                          size={50}
                                          style={{ height: "30px" }}
                                          color={
                                            currentRating <=
                                            (hover || reviewForm?.rating)
                                              ? "#ffc107"
                                              : "#e4e5e9"
                                          }
                                          onMouseEnter={() => {
                                            setHover(currentRating);
                                          }}
                                          onMouseLeave={() => {
                                            setHover(null);
                                          }}
                                        />
                                      </label>
                                    );
                                  })}
                                </div>
                                <p
                                  style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >{`Your rating is ${reviewForm?.rating}`}</p>
                                {errors.rating ? (
                                  <div
                                    className="validation-error-label"
                                    style={{ marginTop: "4px" }}
                                  >
                                    {errors.rating}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label className="fw600 ff-heading mb-2">
                                  Review
                                </label>
                                <textarea
                                  className="pt15"
                                  rows="6"
                                  name="review"
                                  placeholder="Write a Review"
                                  onChange={(e) => {
                                    handleReviewChange(e);
                                  }}
                                  value={reviewForm.review}
                                ></textarea>
                                {errors.review ? (
                                  <div
                                    className="validation-error-label"
                                    style={{ marginTop: "4px" }}
                                  >
                                    {errors.review}
                                  </div>
                                ) : null}
                              </div>
                              <button
                                type="submit"
                                className="ud-btn btn-white2"
                                disabled={buttonDisabled}
                              >
                                Submit Review
                                <i className="fal fa-arrow-right-long"></i>
                              </button>
                              <div
                                className="validation-error-label"
                                style={{ padding: "6px" }}
                              >
                                {formResponseMessage}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="product_single_content">
                        <div className="mbp_pagination_comments">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="total_review d-flex align-items-center justify-content-between mb20">
                                <h6 className="fz17 mb15">Rating & Reviews</h6>
                              </div>
                            </div>

                            {reviewList?.map((review) => {
                              const ratings = Array.from(
                                { length: review?.ratings },
                                (_, index) => index + 1
                              );
                              return (
                                <div className="col-md-12">
                                  <div className="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                                    <img
                                      src={CommentUser}
                                      className="mr-3"
                                      alt="comments-2.png"
                                    />
                                    <div className="ml20">
                                      <h6 className="mt-0 mb-0">
                                        {review?.name}
                                      </h6>
                                      <div>
                                        <span className="fz14">
                                        {review?.created_at}
                                        </span>
                                        <div className="blog-single-review">
                                          <ul className="mb0 ps-0">
                                            {ratings?.map(() => {
                                              return (
                                                <li className="list-inline-item me-0">
                                                  <Link to="">
                                                    <i className="fas fa-star review-color2 fz10"></i>
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: review?.review,
                                    }}
                                    className="text mt20 mb20"
                                  ></p>

                                  <div className="review_cansel_btns d-flex bdrb1 pb30">
                                    <Link to="">
                                      <i className="fas fa-thumbs-up"></i>
                                      Helpful
                                    </Link>
                                    <Link to="">
                                      <i className="fas fa-thumbs-down"></i>Not
                                      helpful
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      }
      
    </>
  );
}

export default About;
