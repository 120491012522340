import { useEffect } from "react";
import Layout from "./Layouts/Layouts";
import { Outlet, useLocation } from "react-router-dom";

function AllRoutes() {
  const pathName = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathName]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default AllRoutes;
