import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import config from "../.././../../Services/config.json";

// import {Button} from "bootstrap/Button"

function OtherEvents({ details }) {
  const [moreEventDetails, setmoreEventDetails] = useState({});
  const loaderRef = useRef("");
  useEffect(() => {
    setmoreEventDetails(details);
    if (details && Object.keys(details).length === 0) {
      loaderRef.current.className = "preloader";
    } else {
      loaderRef.current.classList.remove("preloader");
    }
  }, [details]);

  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section
          className="breadcumb-section3 p-0"
          style={{
            backgroundImage: `url(${config.path}${details?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title text-white text-center">
                    {moreEventDetails?.name}
                  </h2>
                  <p className="fz15 text-white">
                    {moreEventDetails?.sub_title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt60 pb90 bgc-white">
          <div className="container">
            <div className="row wrap wow fadeInUp" data-wow-delay="500ms">
              <div className="col-lg-8">
                <div className="ps-widget bgc-white mb30 overflow-hidden position-relative">
                  <p
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: moreEventDetails?.description,
                    }}
                  ></p>
                  <h3 className="title fz17 mb30 mt30">Services</h3>
                  <div className="row">
                    {moreEventDetails?.event?.map((moreEvent, i) => {
                      return (
                        <div className="col-md-6" key={i}>
                          <div
                            className="listing-style1"
                            style={{ minHeight: "220px" }}
                          >
                            <div className="list-thumb">
                              <img
                                className="w-100"
                                style={{ height: "195px" }}
                                src={config.path + moreEvent?.banner_image}
                                alt=""
                              />
                            </div>
                            <div className="list-content">
                              <h6 className="list-title">
                                <Link
                                  to={`/eventCategory/more-events/${moreEvent?.slug}`}
                                >
                                  {moreEvent?.title}
                                </Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="column">
                  <div className="default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                    <h6 className="title fz17 mb30 text-center">
                      Get More Information
                    </h6>

                    <form className="form-style1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="widget-wrapper sideborder-dropdown mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              I'm a
                            </label>
                            <div className="form-style2 input-group">
                              <select
                                className="form-control"
                                // data-width="100%"
                              >
                                <option>Select</option>
                                <option value="Engineer">Engineer</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Employee">Employee</option>
                                <option value="Businessman">Businessman</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="heading-color ff-heading fw600 mb10">
                              Message
                            </label>
                            <textarea
                              cols="30"
                              rows="4"
                              placeholder="Hello, I am interested in [Renovated apartment at last floor]"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="d-grid">
                            {/* <Link
                              // className="ud-btn btn-thm"
                              to="page-contact.html"
                            >
                              Submit a Tour Request
                              <i className="fal fa-arrow-right-long"></i>
                            </Link> */}
 <button type="button" class="btn btn-primary">Submit</button>                        </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Link className="scrollToHome" to="#">
          <i className="fas fa-angle-up"></i>
        </Link>
      </div>
    </>
  );
}

export default OtherEvents;
