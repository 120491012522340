import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import config from "../../Services/config.json";
import Pagination from "../Layouts/Paginate";

function Blog() {
  const [blog, setBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const loaderRef = useRef("");

  useEffect(() => {
    getBlogDetails();
  }, []);

  const paginateAction = async (page) => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const blogDetails = await GET_API(`blogs?page=${page}`);
      if (blogDetails?.data?.status === 200) {
        setBlog(blogDetails?.data?.data?.data);
        setCurrentPage(blogDetails?.data?.data?.current_page);
        setLastPage(blogDetails?.data?.data?.last_page);
        loaderRef.current.classList.remove("preloader");
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const getBlogDetails = async () => {
    const blogDetails = await GET_API("blogs");
    if (blogDetails?.data?.status === 200) {
      setBlog(blogDetails?.data?.data?.data);
      setCurrentPage(blogDetails?.data?.data?.current_page);
      setLastPage(blogDetails?.data?.data?.last_page);
    }
  };
  const formatDate = (dateString) => {
    const options = { month: "long" };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const formatDay = (dateString) => {
    const options = { day: "numeric" };
    const formattedDay = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDay;
  };
  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section className="pb-0 pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title">Blog</h2>
                  <div className="breadcumb-list">
                    <Link to="/">Home</Link>
                    <Link to="">Blog</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-blog pt-20">
          <div className="container">
            <div className="row wow fadeInUp" data-wow-delay="300ms">
              <div className="col-xl-12">
                <div className="navpill-style1">
                  <div className="row">
                    {blog &&
                      blog.map((blogData, i) => (
                        <div className="col-sm-6 col-lg-4" key={i}>
                          <div className="blog-style1">
                            <div className="blog-img">
                              <img
                                className="w-100"
                                src={`${config.path}${blogData?.banner_image}`}
                                alt=""
                              />
                            </div>
                            <div className="blog-content">
                              <div className="date">
                                <span className="month">
                                  {formatDate(blogData.created_at)}
                                </span>
                                <span className="day">
                                  {formatDay(blogData.created_at)}
                                </span>
                              </div>
                              <Link className="tag" to="">
                                <strong>{blogData?.title}</strong> | {blogData?.sub_title}
                              </Link>
                              <h6 className="title mt-1">
                                <Link href="">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: blogData?.description,
                                    }}
                                  />
                                </Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              paginationClick={paginateAction}
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default Blog;
