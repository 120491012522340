import React, { useEffect, useRef, useState } from "react";
import element1 from "../../assets/images/about/element-1.png";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import CameoBackground from "../../assets/images/675.jpg";
import { useNavigate } from "react-router-dom";
import config from "../../Services/config.json";
import HomeSlider from "../../Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Home() {
  const navigate = useNavigate();
  const loaderRef = useRef("");
  const [testimonialSliderSettings, setTestimonialSliderSettings] = useState({
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  });

  const [bannerData, setBannerData] = useState([]);
  const [faqCat, setFaqCat] = useState([]);

  const [blog, setBlog] = useState({
    title: "",
    description: "",
    data: [],
  });

  const [event, setEvent] = useState({
    title: "",
    description: "",
    data: [],
  });

  const [services, setServices] = useState({
    title: "",
    description: "",
    data: [],
  });

  const [testimonial, setTestimonial] = useState({
    title: "",
    description: "",
    data: [],
  });

  const [cameo, setCameo] = useState({});
  const [property, setProperty] = useState({});
  const [discount, setDiscount] = useState([]);
  useEffect(() => {
    getHomePageDetails();
    getFaqDetails();
  }, []);

  useEffect(() => {
    configureTestimonialSlider();
    window.addEventListener("resize", configureTestimonialSlider);
    return () => {
      window.removeEventListener("resize", configureTestimonialSlider);
    };
  }, []);

  const getHomePageDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const homePageDetails = await GET_API("home");
      if (homePageDetails?.data?.status === 200) {
        setBannerData(homePageDetails?.data?.banner);
        setBlog(homePageDetails?.data?.blog);
        setEvent(homePageDetails?.data?.event);
        setServices(homePageDetails?.data?.service);
        setTestimonial(homePageDetails?.data?.testimonial);
        setCameo(homePageDetails?.data?.cameo);
        setProperty(homePageDetails?.data?.property);
        setDiscount(homePageDetails?.data?.discount);
        loaderRef.current.classList.remove("preloader");
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const getFaqDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const homePageDetails = await GET_API("faqList");
      if (homePageDetails?.data?.status === 200) {
        setFaqCat(homePageDetails?.data?.data);
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const configureTestimonialSlider = () => {
    const screenWidthSize = window.innerWidth;

    if (screenWidthSize < 425) {
      setTestimonialSliderSettings({
        ...testimonialSliderSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
      return;
    }

    if (screenWidthSize < 768) {
      setTestimonialSliderSettings({
        ...testimonialSliderSettings,
        slidesToShow: 2,
        slidesToScroll: 2,
      });
      return;
    }

    setTestimonialSliderSettings({
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    });
  };

  const handleVideo = () => {
    navigate("/reel");
  };

  const formatDate = (dateString) => {
    const options = { month: "long" };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };

  const formatDay = (dateString) => {
    const options = { day: "numeric" };
    const formattedDay = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDay;
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent.slice(0, 120) + "..." || "";
  }

const adjustContentHeights = () => {
  const contentItems = document.querySelectorAll(".testimonial-content");
  let maxHeight = 0;

  contentItems.forEach((item) => {
    item.style.height = "auto"; // Reset height to calculate correctly
    const itemHeight = item.offsetHeight;
    if (itemHeight > maxHeight) {
      maxHeight = itemHeight;
    }
  });

  contentItems.forEach((item) => {
    item.style.height = `${maxHeight}px`;
  });
};

useEffect(() => {
  adjustContentHeights();
  window.addEventListener("resize", adjustContentHeights);

  return () => {
    window.removeEventListener("resize", adjustContentHeights);
  };
}, []);


  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <HomeSlider slides={bannerData} />
        <section className="pb90 pb-0">
          <div className="container">
            <div className="row wow fadeInUp" data-wow-delay="00ms">
              <div className="col-lg-9">
                <div className="main-title2">
                  <h2 className="title">{services?.title}</h2>
                  <p className="paragraph">{services?.description}</p>
                </div>
              </div>
            </div>
            <div className="row wow fadeInUp" data-wow-delay="300ms">
              {services?.data?.map((serviceList, i) => {
                return (
                  <div className="col-auto px-1" key={i}>
                    <Link
                      to={`/eventCategory/${serviceList?.parent_slug}/${serviceList?.slug}`}
                    >
                      <div className="apartment-category d-flex align-items-center">
                        <span className="icon flaticon-bird-house flex-shrink-0"></span>
                        <div className="content flex-shrink-1">
                          <h6 className="title mb-0">{serviceList?.name}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section
          className=" pb40-md"
          style={{
            backgroundImage: `url(${CameoBackground})`,
            backgroundColor: "#ccc",
            color: "red",
          }}
        >
          <div className="cta-banner mx-auto maxw1600  bdrs12 position-relative mx20-lg px20-sm">
            <div className="container">
              <div className="row align-items-start ">
                <div className="col-md-6 col-xl-6">
                  <div
                    className="position-relative wow fadeInRight"
                    data-wow-delay="300ms"
                    style={{
                      visibility: "visible",
                      animationDelay: "300ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <div className="img-box-7">
                      <img
                        className="w-100 img-1"
                        src={config?.path + cameo?.banner_image}
                        alt=""
                      />
                    </div>
                    <div className="img-box-8 position-relative">
                      <img className="img-1 spin-right" src={element1} alt="" />
                    </div>
                    <div className="img-box-9 d-flex justify-content-center align-items-center">
                      <div
                        className="popup-iframe popup-youtube"
                        onClick={() => {
                          handleVideo();
                        }}
                      >
                        <i className="fas fa-circle-play mr15"></i>
                      </div>
                      <h6 className="fz14 mb-0">Watch Video</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-lg-5 col-xl-5 offset-xl-1">
                  <div
                    className="about-box-1  wow fadeInLeft"
                    data-wow-delay="300ms"
                    style={{
                      visibility: "visible",
                      animationDelay: "300ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <h2 className="title mb20 text-white">{cameo?.name}</h2>
                    <h2
                      style={{ fontSize: "12px", lineHeight: "2" }}
                      className="text mb15 mb30-md  text-white"
                      dangerouslySetInnerHTML={{
                        __html: cameo?.description,
                      }}
                    ></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="cta-banner4 d-flex align-items-center pn"
          data-stellar-background-ratio="0.1"
          style={{
            backgroundPosition: "50% 8.02085px",
            backgroundImage: `url(${config?.path + property?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-xl-10 mx-auto wow fadeInUp"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="cta-style4 position-relative text-center">
                  <h6 className="sub-title fw400 text-white">
                    {property?.title}
                  </h6>
                  <h1
                    className="cta-title mb30 text-whiten property-desc"
                    dangerouslySetInnerHTML={{
                      __html: property?.description,
                    }}
                  ></h1>
                  <div className="d-block d-sm-flex justify-content-center">
                    <Link
                      target="_blank"
                      to={property?.button_1_url ? property?.button_1_url : "#"}
                      className="ud-btn btn-thm me-0 me-sm-4"
                      style={{ opacity: "unset" }}
                    >
                      {property?.button_1
                        ? property?.button_1
                        : "Submit Property"}
                      <i className="fal fa-arrow-right-long"></i>
                    </Link>
                    <Link
                      target="_blank"
                      to={property?.button_2_url ? property?.button_2_url : "#"}
                      className="ud-btn btn-white"
                      style={{ opacity: "unset" }}
                    >
                      {property?.button_2
                        ? property?.button_2
                        : "Browse Properties"}
                      <i className="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md pt-60">
          <div className="container">
            <div
              className="row wow fadeInUp "
              data-wow-delay="00ms"
              style={{ marginBottom: "4rem!important" }}
            >
              <div className="col-lg-6">
                <div className="main-title2">
                  <h2 className="title">{event?.title}</h2>
                  <p className="paragraph">{event?.description}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="dark-light-navtab style2 text-start text-lg-end mt-0 mt-lg-4 mb-4">
                  <ul
                    className="nav nav-pills justify-content-start justify-content-lg-end"
                    id="pills-tab"
                    role="tablist"
                  >
                    {event?.data?.map((item, i) => {
                      return (
                        <li key={i} className="nav-item" role="presentation">
                          <button
                            className={
                              "nav-link " +
                              (item?.slug === "corporate-events"
                                ? "active"
                                : "")
                            }
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target={"#" + item?.slug}
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            style={{ marginBottom: "1rem" }}
                          >
                            {item?.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="300ms">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="corporate-events"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      {event?.data[0]?.event?.map((eventList, i) => {
                        return (
                          <div className="col-sm-6 col-lg-4 col-xl-4" key={i}>
                            <div className="item">
                              <div className="listing-style1 style2">
                                <div className="list-thumb">
                                  <img
                                    className="w-100"
                                    style={{ height: "195px" }}
                                    src={config?.path + eventList?.banner_image}
                                    alt=""
                                  />
                                </div>
                                <div className="list-content">
                                  <h6 className="list-title">
                                    <Link
                                      to={`eventCategory/${event?.data[0]?.slug}/${eventList?.slug}`}
                                    >
                                      {eventList?.title}
                                    </Link>
                                  </h6>

                                  <p
                                    className="text mb-0 testomonial-desc"
                                    dangerouslySetInnerHTML={{
                                      __html: stripHtmlTags(
                                        eventList?.description || ""
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="social-event"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      {event?.data[1]?.event?.map((eventList, i) => {
                        return (
                          <div className="col-sm-6 col-lg-4 col-xl-4" key={i}>
                            <div className="item">
                              <div className="listing-style1 style2">
                                <div className="list-thumb">
                                  <img
                                    className="w-100"
                                    style={{ height: "195px" }}
                                    src={config?.path + eventList?.banner_image}
                                    alt=""
                                  />
                                </div>
                                <div className="list-content">
                                  <h6 className="list-title">
                                    <Link
                                      to={`eventCategory/${event?.data[1]?.slug}/${eventList?.slug}`}
                                    >
                                      {eventList?.title}
                                    </Link>
                                  </h6>

                                  <p
                                    className="text mb-0 testomonial-desc"
                                    dangerouslySetInnerHTML={{
                                      __html: stripHtmlTags(
                                        eventList?.description || ""
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="more-events"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      {event?.data[2]?.event?.map((eventList, i) => {
                        return (
                          <div className="col-sm-6 col-lg-4 col-xl-4" key={i}>
                            <div className="item">
                              <div className="listing-style1 style2">
                                <div className="list-thumb">
                                  <img
                                    className="w-100"
                                    style={{ height: "195px" }}
                                    src={config?.path + eventList?.banner_image}
                                    alt=""
                                  />
                                </div>
                                <div className="list-content">
                                  <h6 className="list-title">
                                    <Link
                                      to={`eventCategory/${event?.data[2]?.slug}/${eventList?.slug}`}
                                    >
                                      {eventList?.title}
                                    </Link>
                                  </h6>

                                  <p
                                    className="text mb-0 testomonial-desc"
                                    dangerouslySetInnerHTML={{
                                      __html: stripHtmlTags(
                                        eventList?.description || ""
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-cta pt0 pb-0">
          <div className="cta-banner bg3 mx-auto pt120 pt60-md pb120 pb60-md  position-relative mx20-lg">
            <div className="img-box-5">
              <img
                className="img-1 spin-right"
                src="images/about/element-1.png"
                alt=""
              />
            </div>
            <div className="img-box-6">
              <img
                className="img-1 spin-left"
                src="images/about/element-1.png"
                alt=""
              />
            </div>
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-xl-6 wow fadeInLeft"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInLeft",
                  }}
                >
                  <div className="cta-style1">
                    <h2 className="cta-title text-white">{discount?.name}</h2>
                    <p className="cta-text text-white mb-0">
                      {discount?.description}
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-5 col-xl-6 wow fadeInRight"
                  data-wow-delay="300ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "300ms",
                    animationName: "fadeInRight",
                  }}
                >
                  <div className="cta-btns-style1 d-block d-sm-flex align-items-center justify-content-lg-end">
                    <Link
                      to="mailto:cameoevents1@gmail.com"
                      className="ud-btn btn-transparent mr30 mr0-xs"
                    >
                      Email<i className="fal fa-arrow-right-long"></i>
                    </Link>
                    <Link to="tel:+919539818364" className="ud-btn btn-dark">
                      <span className="flaticon-call vam pe-2"></span>95398
                      18364
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="cta-banner4 d-flex align-items-center pn"
          data-stellar-background-ratio="0.1"
          style={{
            backgroundPosition: "50% 8.02085px",
            backgroundImage: `url(${config?.path + property?.banner_image})`,
          }}
        >
          <div className="container maxw1600">
            <div className="row">
              <div
                className="col-lg-6 mx-auto wow fadeInUp"
                data-wow-delay="00ms"
                style={{
                  visibility: "visible",
                  animationDelay: "0ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="main-title text-center">
                  <h2 className="title text-white">{testimonial?.title}</h2>
                  <p className="paragraph text-white">
                    {testimonial?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%", overflow: "hidden" }}>
              <div className="col-lg-12">
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Slider {...testimonialSliderSettings}>
                      {testimonial?.data?.map((list, i) => {
                        return (
                          <div key={i} className="owl-item cloned">
                            <div className="item">
                              <div
                                className="testimonial-style3 mt-1 mx-1 position-relative mb60"
                                style={{ maxWidth: "400px" }}
                              >
                                <div className="testimonial-content">
                                  <span className="icon">“</span>
                                  <div className="thumb d-flex align-items-center mb40">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="wa"
                                        src={config?.path + list?.banner_image}
                                        style={{
                                          height: "55px",
                                          width: "55px",
                                          borderRadius: "55px",
                                        }}
                                        alt=""
                                      />
                                    </div>
                                    <div className="flex-grow-1 ml20">
                                      <h6 className="mb-0 fz14">
                                        {list?.name}
                                      </h6>
                                      <p className="mb-0 fz13 body-light-color">
                                        {list?.sub_title}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    className="text mb-0 testomonial-desc"
                                    dangerouslySetInnerHTML={{
                                      __html: list?.description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {testimonial?.data?.map((list, i) => {
                        return (
                          <div key={i} className="owl-item cloned">
                            <div className="item">
                              <div
                                className="testimonial-style3 mt-1 mx-1 position-relative mb60"
                                style={{ maxWidth: "400px" }}
                              >
                                <div className="testimonial-content">
                                  <span className="icon">“</span>
                                  <div className="thumb d-flex align-items-center mb40">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="wa"
                                        src={config?.path + list?.banner_image}
                                        style={{
                                          height: "55px",
                                          width: "55px",
                                          borderRadius: "55px",
                                        }}
                                        alt=""
                                      />
                                    </div>
                                    <div className="flex-grow-1 ml20">
                                      <h6 className="mb-0 fz14">
                                        {list?.name}
                                      </h6>
                                      <p className="mb-0 fz13 body-light-color">
                                        {list?.sub_title}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    className="text mb-0 testomonial-desc"
                                    dangerouslySetInnerHTML={{
                                      __html: list?.description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb20-md bgc-f7">
          <div className="container ">
            <div className="row" style={{ marginBottom: "3rem!important" }}>
              <div
                className="col-lg-6 m-auto wow fadeInUp "
                data-wow-delay="00ms"
                style={{
                  visibility: "visible",
                  animationDelay: "0ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="main-title text-start text-md-center">
                  <h2 className="title">{blog?.title}</h2>
                  <p className="paragraph">{blog?.description}</p>
                </div>
              </div>
            </div>
            <div
              className="row wow fadeInUp"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInUp",
              }}
            >
              {blog?.data?.map((blogList, i) => {
                return (
                  <div className="col-sm-6 col-lg-4" key={i}>
                    <div className="blog-style1">
                      <div className="blog-img">
                        <img
                          className="w-100"
                          src={config.path + blogList?.banner_image}
                          alt=""
                        />
                      </div>
                      <div className="blog-content">
                        <div className="date">
                          <span className="month">
                            {formatDate(blogList?.created_at)}
                          </span>
                          <span className="day">
                            {formatDay(blogList?.created_at)}
                          </span>
                        </div>

                        <h6 className="title mt-1">
                          <Link to="">{blogList?.title}</Link>
                        </h6>
                        <Link className="tag" to="">
                          {blogList?.sub_title}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <div className="container ">
          <div className="row" style={{ marginBottom: "3rem!important" }}></div>
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcumb-style1">
                    <h2 className="title">Frequently Asked Questions</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="our-faq pb90 pt-0">
            <div className="container">
              <div className="row wow fadeInUp" data-wow-delay="300ms">
                <div className="col-lg-12">
                  {faqCat &&
                    faqCat.map((faqcat, i) => {
                      return (
                        <div className="ui-content" key={i}>
                          <h4 className="title">{faqcat?.name}</h4>
                          <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                            <div className="accordion" id="accordionExample">
                              {faqcat?.faq &&
                                faqcat?.faq.map((faq, key) => {
                                  return (
                                    <div className="accordion-item" key={key}>
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          className="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapseOne${i}-${key}`}
                                          aria-expanded="true"
                                          aria-controls={`collapseOne${i}-${key}`}
                                        >
                                          {faq?.question}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapseOne${i}-${key}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          {faq?.answer}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Home;
